<script lang="ts" setup>
  import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import type { PlanBannerConfig } from '~/composables/PlanBanners'
  import QuoteCardHeader from '~/components/quote/card/QuoteCardHeader.vue'
  import QuoteCardFooter from '~/components/quote/card/QuoteCardFooter.vue'

  const props = defineProps<{
    quote: QuotedPlanVm
    id?: string
    banner?: Partial<PlanBannerConfig & { text: string }> | null | undefined
  }>()

  const { getId } = Id.use(props.id ?? props.quote.medicareId)
</script>

<template>
  <QuoteCardBase :id="getId()" :quote="quote" class="default">
    <template #header>
      <QuoteCardHeader :quote="quote" :banner="banner" :id="getId('header')" />
    </template>
    <template #default="{ showNeedsSection, showPlanTags, showCosts }">
      <QuoteCardSectionPlanTags :quote="quote" :id="getId(`plan-tags-section`)" v-if="showPlanTags" />
      <QuoteCardSectionCostHighlights :quote="quote" :id="getId(`costs-section`)" v-if="showCosts" />
      <QuoteCardSectionNeeds :quote="quote" :id="getId(`needs-section`)" v-if="showNeedsSection" />
      <QuoteCardSectionActions :quote="quote"/>
    </template>
    <template #footer>
      <QuoteCardFooter :quote="quote" />
    </template>
  </QuoteCardBase>
</template>

<style>

</style>
