<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { PlanType } from '~/generated/api-clients-generated'
  import { EngineStore } from '~/stores/engine'

  const props = defineProps<{
    id: string
    quote: QuotedPlanVm
  }>()

  const { flag } = FeatureFlags.use()
  const { maybeDo } = ActionGuard.use()
  const { show } = DialogController.use()
  const { quotingConfig } = QuotingConfig.use()

  const isMapd = computed(() => props.quote.type === PlanType.MAPD)

  const { getId } = Id.use(props.id)

  const engine = EngineStore.use()
  const emptyState = computed(() => engine.isEmptyState)

  const showDrugNeedCoverageLink = computed(() => flag<boolean>('drug-need-coverage-link').value)
  const showGenericOptionsLink = computed(() => flag<boolean>('see-generic-options').value)
  const showBenefitNeedCoverageLink = computed(
    () => flag<boolean>('benefit-need-coverage-link').value
  )
  const showDoctorNeedCoverageLink = computed(
    () => flag<boolean>('doctor-need-coverage-link').value
  )

  provide(Keys.Accordion, {
    light: false,
    compact: false,
    smallToggle: false
  })

  const { getDisplayedBenefits } = await BenefitDetailsMap.use()
  const displayedBenefits = computed(() => getDisplayedBenefits(props.quote))
</script>

<template>
  <section>
    <header class="content-section-header bg-sky-100">
      <div>
        <HpIcon name="document-check" class="text-primary-600" />
        <h2>Your Needs</h2>
      </div>
      <HpAnchor
        class="action-link shrink-icon"
        v-if="emptyState"
        @click.prevent="() => maybeDo(() => show('update-insurance-sidebar'))"
      >
        <HpIcon name="add" />
        Add
      </HpAnchor>
      <HpAnchor
        class="action-link shrink-icon"
        @click.prevent="() => maybeDo(() => show('update-insurance-sidebar'))"
        v-else
      >
        <HpIcon name="edit" />
        Edit
      </HpAnchor>
    </header>
    <div class="content-section-data">
      <div
        class="content-row providers"
        v-if="isMapd && (quote.providerCoverage.count > 0 || quotingConfig.emptyAddNeeds)"
      >
        <template v-if="quote.providerCoverage.count > 0">
          <HpAccordionItem title="Doctors In-Network" class="coverage-disclosure">
            <template #header-detail>
              {{ quote.providerCoverage.inNetworkCount }} of
              {{ quote.providerCoverage.count }} covered
            </template>
            <ul class="coverage-list" v-for="dr in quote.providerCoverage.providers">
              <li :class="['coverage-item', { covered: !!dr.inNetwork }]">
                <div>
                  <HpIcon
                    v-if="dr.inNetwork"
                    class="scale-[65%] stroke-success text-success"
                    name="check"
                  />
                  <HpIcon v-else class="text-danger" name="no" />
                  <span>{{ dr.name }}</span>
                </div>
                <div class="coverage-item-actions">
                  <HpAnchor v-if="!dr.inNetwork && showDoctorNeedCoverageLink"
                    >I need coverage for this doctor
                  </HpAnchor>
                </div>
              </li>
            </ul>
          </HpAccordionItem>
        </template>
        <template v-else>
          <div>Doctors In-Network</div>
          <HpAnchor
            class="action-link shrink-icon text-base"
            @click.prevent="() => maybeDo(() => show('update-doctors-sidebar'))"
          >
            <HpIcon name="add" />
            Add Doctors
          </HpAnchor>
        </template>
      </div>
      <div
        class="content-row drugs"
        v-if="quote.drugCoverage.count > 0 || quotingConfig.emptyAddNeeds"
      >
        <template v-if="quote.drugCoverage.count > 0">
          <HpAccordionItem title="Drugs Covered" class="coverage-disclosure">
            <template #header-detail>
              {{ quote.drugCoverage.coveredCount }} of {{ quote.drugCoverage.count }} covered
            </template>
            <ul class="coverage-list" v-for="rx in quote.drugCoverage.coverageSummaries">
              <li :class="['coverage-item', { covered: !!rx.isCovered }]">
                <div>
                  <HpIcon v-if="rx.isCovered" class="w-7 self-start text-success" name="yes" />
                  <HpIcon v-else class="self-start text-danger" name="no" />
                  <span class="break-all leading-snug">{{ rx.drugName }}</span>
                </div>
                <div class="coverage-item-actions">
                  <HpAnchor v-if="!rx.isCovered && showDrugNeedCoverageLink"
                    >I need coverage for this drug
                  </HpAnchor>
                  <HpAnchor v-if="!rx.isCovered && showGenericOptionsLink"
                    >See generic options
                  </HpAnchor>
                </div>
              </li>
            </ul>
          </HpAccordionItem>
        </template>
        <template v-else>
          <div>Drug Coverage</div>
          <HpAnchor
            class="action-link shrink-icon text-base"
            @click.prevent="() => maybeDo(() => show('update-drugs-sidebar'))"
          >
            <HpIcon name="add" />
            Add Drugs
          </HpAnchor>
        </template>
      </div>
      <div
        class="content-row pharmacy"
        v-if="
          (quotingConfig.emptyAddNeeds && quote.drugCoverage.count > 0) ||
          quote.pharmacyCoverage?.pharmacies?.length > 0
        "
      >
        <template v-if="!_isNil(quote.pharmacyCoverage.first)">
          <HpAccordionItem title="Your Pharmacy" class="coverage-disclosure">
            <template #header-detail>
              {{ quote.pharmacyCoverage.coverageDescription }}
            </template>
            <ul class="coverage-list" v-for="pharm in [quote.pharmacyCoverage.first]">
              <li :class="['coverage-item', { covered: pharm.inNetwork || pharm.isPreferred }]">
                <div>
                  <HpIcon
                    v-if="pharm.inNetwork || pharm.isPreferred"
                    class="scale-[65%] stroke-success text-success"
                    name="check"
                  />
                  <HpIcon v-else class="text-danger" name="no" />
                  <span>{{ pharm.name }} - {{ quote.pharmacyCoverage.coverageDescription }}</span>
                </div>
              </li>
            </ul>
          </HpAccordionItem>
        </template>
        <template v-else>
          <div>Your Pharmacy</div>
          <HpAnchor
            class="action-link shrink-icon text-base"
            @click.prevent="() => maybeDo(() => show('update-pharmacy-sidebar'))"
          >
            <HpIcon name="add" />
            Add Pharmacy
          </HpAnchor>
        </template>
      </div>
      <div
        class="content-row benefits"
        v-if="isMapd && (quote.additionalBenefits.count > 0 || quotingConfig.emptyAddNeeds)"
      >
        <template v-if="quote.additionalBenefits.count > 0">
          <HpAccordionItem title="Extra Benefits" class="coverage-disclosure">
            <template #header-detail>
              {{ quote.additionalBenefits.coveredCount }} of
              {{ quote.additionalBenefits.count }} covered
            </template>
            <ul class="coverage-list" v-for="ben in displayedBenefits">
              <li :class="['coverage-item', { covered: !!ben.covered }]">
                <div>
                  <HpIcon
                    v-if="ben.covered"
                    class="scale-[65%] stroke-success text-success"
                    name="check"
                  />
                  <HpIcon v-else class="text-danger" name="no" />
                  <span
                    class="ants"
                    v-if="ben.covered"
                    @click="show(getId(`${ben.name}-benefit-details-sidebar`))"
                    >{{ ben.text }}</span
                  >
                  <span v-else>{{ ben.text }}</span>
                </div>
                <div class="coverage-item-actions">
                  <HpAnchor v-if="!ben.covered && showBenefitNeedCoverageLink"
                    >I need coverage for this benefit
                  </HpAnchor>
                </div>
              </li>
            </ul>
          </HpAccordionItem>
        </template>
        <template v-else>
          <div>Extra Benefits</div>
          <HpAnchor
            class="action-link shrink-icon text-base"
            @click.prevent="() => maybeDo(() => show('update-add-benefits-sidebar'))"
          >
            <HpIcon name="add" />
            Add Benefits
          </HpAnchor>
        </template>
      </div>
    </div>
  </section>
  <QuoteCardBenefitDetailsSidebar
    v-for="ben in displayedBenefits"
    :icon="ben.icon"
    :label="ben.text"
    :id="getId(`${ben.name}-benefit-details-sidebar`)"
    :details="ben.details"
  />
</template>

<style scoped></style>
