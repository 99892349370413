import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

export interface CostConfigItem {
  label: string
  key: string
  tooltip: string
}

export interface QuoteCardCostConfiguration {
  empty: CostConfigItem[]
  filled: CostConfigItem[]
}

export default class QuoteCardCosts {
  static use(quote: QuotedPlanVm) {
    const { flag } = FeatureFlags.use()
    const costConfig = computed(
      () => flag<QuoteCardCostConfiguration>('quote-card-cost-configuration').value
    )
    const elements = computed(() => flag<string[]>('quote-cost-elements').value)

    //class getters don't play nicely with reactivity, so a couple of these are functions
    const valueMap = computed<Record<string, any>>(() => ({
      monthly_premium: quote.details.premium,
      max_oop: quote.details.maxOutOfPocket,
      yearly_deductible: quote.details.medicalDeductible,
      estimated_yearly_drug_costs: (q: QuotedPlanVm) => {
        if(q.details.year === 2025 && q.drugCoverage.allCovered) {
          return _clamp(q.drugCost, 2000)
        }
        return q.drugCost
      },
      expected_all_in_costs: (q: QuotedPlanVm) => q.recommendation.medicalCost,
      yearly_premium_plus_drug_costs: (q: QuotedPlanVm) =>
        q.drugCost + q.recommendation.annualPremium,
      drug_deductible: quote.details.drugDeductible
    }))

    const mapCostConfig = ({ key, label, tooltip }: CostConfigItem) => ({
      key,
      label,
      value: valueMap.value[key],
      tooltip
    })

    return {
      mapdEmpty: computed(() => costConfig.value.mapd_empty.map(mapCostConfig)),
      mapdFilled: computed(() => costConfig.value.mapd_filled.map(mapCostConfig)),
      pdpEmpty: computed(() => costConfig.value.pdp_empty.map(mapCostConfig)),
      pdpFilled: computed(() => costConfig.value.pdp_filled.map(mapCostConfig))
    }
  }
}
