<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { EngineStore } from '~/stores/engine'
  import { ProfileStore } from '~/stores/profile'
  import {SessionStore} from "~/stores/session";
  import {PlanType} from "~/generated/api-clients-generated";
  const props = defineProps<{
    id: string,
    quote: QuotedPlanVm
  }>()

  const engine = EngineStore.use()
  const profile = ProfileStore.use()
  const session = SessionStore.use()
  const emptyState = computed(() => engine.isEmptyState)

  const { hydratedTags } = await PlanTags.use()
  const { getComponentContent, hydratePlaceholders } = Cms.use()
  const { content } = await getComponentContent('QuoteCard')
  const tags = computed(() => _compact(props.quote.planAttributes.map(attr => hydratedTags.value[attr])))

  const emptyStateText = computed(() => {

    switch (true) {
      case session.planType === PlanType.PDP:
        return content.value?.ext.empty_plan_fit_text_pdp.replace('{{countyName}}',  profile.location.countyName)
      case session.planType === PlanType.MAPD:
        return content.value?.ext.empty_plan_fit_mapd.replace('{{countyName}}',  profile.location.countyName)
      default:
        return content.content.value?.ext.empty_plan_fit_text.replace('{{countyName}}',  profile.location.countyName)
    }
  })

</script>

<template>
  <section v-if="!emptyState && !_isNil(tags)" class="grow plan-tags-section">
    <header class="content-section-header bg-primary-100">
      <div>
        <HpIcon name="heart" class="text-primary-600" />
        <h2>Why this plan fits you</h2>
      </div>
    </header>
    <ul class="quote-fit-tags" v-if="tags.length > 0">
      <li v-for="tag in tags">
        <HpIcon name="check" class="text-success" />
        <span>{{ tag }}</span>
      </li>
    </ul>
    <div
      v-else
      class="text-text-light p-4 text-center leading-tight text-gray-600"
      v-html="
            hydratePlaceholders(emptyStateText, {
              countyName: profile.location.countyName
            })
          "
    ></div>
  </section>
</template>

<style scoped>

</style>